#root{
    height: 100vh;
}

html{
    font: 16px Helvetica;
}

body{
    height: 100%;
    margin: 0;
    background-color: #FFFFFF;
}

.App{
    display: grid;
}

.pictureArea{
    width: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;
}

.trxcImage{
    height: auto;
    width: 256px;
}

.nameArea{
    height: 32px;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 16px;
}

.nameField{
    height: 32px;
    width: 256px;
    border-radius: 8px;
    border-width: thin;
    border-style: solid;
    text-align: center;
}

.paymentAmount{
    width: auto;
    height: 64px;
    margin-left: auto;
    margin-right: auto;
    display: inline-flex;
    margin-top: 32px;
}

.dollarsField{
    height: 32px;
    width: 64px;
    border-radius: 8px;
    border-width: thin;
    border-style: solid;
    text-align: right;
}

.centsField{
    height: 32px;
    width: 32px;
    border-radius: 8px;
    border-width: thin;
    border-style: solid;
    text-align: center;
}

.buttonArea{
    height: 32px;
    width: auto;
    margin-left: auto;
    margin-right: auto;
}

.payButton{
    height: 32px;
    width: 128px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    border-width: thin;
    border-style: solid;
}
